import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React, { Component } from 'react'

export class R2OUserSelector extends Component {
    render() {
        return (
            <FormControl fullWidth>
                <InputLabel shrink={this.props.currentId != null} id="demo-simple-select-label">Benutzer</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.props.currentId}
                    label="Benutzer"
                    onChange={(evt) => { this.props.changeSelection(evt.target.value) }}
                >
                    {this.props.userList?.map(item => {
                        return <MenuItem key={item.user_id} value={item.user_id}>{item.user_id} - {item.user_firstName}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }
}

export default R2OUserSelector
