import { AppBar, IconButton, Toolbar, Tooltip, Typography, colors } from '@mui/material'
import React, { Component } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { ArrowBack, Logout } from '@mui/icons-material'
import { withNavigate } from '../hookInjection/withNavigate'
import { destroySession } from '../APIHelper'

export class Header extends Component {
    constructor(props) {
        super(props);
        console.log("HEADERPROPS: ", props);
    }
    goBack() {
        if (window.history.length > 0) {
            this.props.navigate(-1);
        }
    }
    logout(){
        destroySession().then(() => {
            this.props.navigate("/?logout");
        }).catch(e => {
            console.log("Error while logout: ",e);
        })
    }
    render() {
        return (
            <AppBar style={{ maxHeight: 100 }} position='static'>
                {/* <Toolbar style={{backgroundColor: "#1bb5cd"}}> */}
                <Toolbar style={{ backgroundColor: "#353535" }}>
                    {!this.props.noMenu &&
                        <Tooltip title="Menü">
                            <IconButton
                                size='large' edge="start" color='inherit' aria-label='menu' sx={{ mr: 2 }}>
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <img onClick={() => {this.props.navigate("/")}} height={32} width={32} src={require("../assets/icons/viveel_logo128x128.png")} alt='Logo' />
                    <Typography component="div" sx={{ flexGrow: 1 }} style={{ paddingLeft: 25, verticalAlign: "center" }} variant="h6">{this.props.title}</Typography>
                    {!this.props.noBack &&
                        <Tooltip title="Zurück">
                            <IconButton onClick={() => { this.goBack() }} size='large' aria-label='arrow' color='inherit' sx={{ mr: 2 }}>
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                    }
                    {!this.props.noLogout &&
                        <Tooltip title="Abmelden">
                            <IconButton onClick={() => { this.logout()}} size='large' aria-label='logout' color='inherit' sx={{ mr: 2 }}>
                                <Logout />
                            </IconButton>
                        </Tooltip>
                    }

                </Toolbar>
            </AppBar>
        )
    }
}

export default withNavigate(Header)
