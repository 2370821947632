import React, { Component } from 'react'
import { PermissionAreaComponent } from './hookInjection/PermissionAreaComponent'
import { connect } from 'react-redux'
import { setRole } from './redux/permissionSlice'
import "./assets/css/general.css";
import Header from './Components/Header';
import DashboardButton from './Components/DashboardButton';

export class POSIntegration extends Component {
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className='mainView'>
                <Header title="Kassenanbindung"/>
                <div style={{ display:"flex", flexWrap:"wrap", justifyContent:"center", marginTop:"2.5%"}}>
                    <DashboardButton path="./expertorder" title="ExpertOrder" iconSrc={require("./assets/poslogos/eo-logo.png")} />
                    <DashboardButton path="./liefersoft" title="Liefersoft" iconSrc={require("./assets/poslogos/liefersoft-logo.png")}/>
                    <DashboardButton path="./ready2order" title="Ready2Order" iconSrc={require("./assets/poslogos/r2o-logo.png")}/>
                    <DashboardButton path="./winorder" title="WinOrder" iconSrc={require("./assets/poslogos/wo-logo.png")}/>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        role: state.permission.role
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionAreaComponent(POSIntegration))
// export default PermissionAreaComponent(POSIntegration)
