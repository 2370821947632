import { configureStore } from "@reduxjs/toolkit";
import permissionSlice from "./permissionSlice";
import appSlice from "./appSlice";

export default configureStore({
    reducer: {
        permission: permissionSlice,
        app: appSlice
        // support: supportSlice,
        // general: generalSlice,
        // userManagement: userManagementSlice
    }
})