import React, { Component } from 'react'
import "./assets/css/general.css";
import Header from './Components/Header';
import { Button, Typography } from '@mui/material';
import { connect } from 'react-redux';
import DashboardButton from './Components/DashboardButton';
import {  ROLES, setRole } from './redux/permissionSlice';
import { PermissionAreaComponent } from './hookInjection/PermissionAreaComponent';

// const supportNS = io('https://viveel.de:3002/support', {withCredentials: true});
// supportNS.on("nsm", (scid)=> {
//     alert("Neuer Support Nachricht in Case: "+scid);
// })

class Dashboard extends Component {
    constructor(props){
        super(props);
        console.log("ENV: ",process.env.REACT_APP_TEST_APIKEY)
        console.log("ENV: ",process.env)
    }
    render() {
        return (
            <div style={{display:"flex",flexDirection:"column",  height:"100%"}} className='mainView'>
                <Header title="Dashboard" noBack/>
                <div style={{ display:"flex", flexWrap:"wrap", justifyContent:"center", marginTop:"2.5%"}}>
                    <DashboardButton path="/posconfig" title="Kassenanbindung" iconSrc={require("./assets/icons/pos-terminal.png")} />
                    <DashboardButton path="/invoice" title="Rechnungen" iconSrc={require("./assets/icons/invoice.png")}/>
                    {/* <DashboardButton path="/orders" title="Bestellverwaltung" iconSrc={require("./assets/icons/box.png")}/> */}
                    {/* <DashboardButton path="/partners" title="Partnermanagement" iconSrc={require("./assets/icons/store.png")}/> */}
                </div>
            </div>
        )
    }
}
function mapStateToProps(state){
    return {
        role: state.permission.role
    }
}
function mapDispatchToProps(dispatch){
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionAreaComponent(Dashboard));
