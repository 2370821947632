import React, { Component } from 'react'
import Header from './Components/Header'
import "./assets/css/general.css"
import { Button, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ROLES, setFullname, setLoggedIn, setRole } from './redux/permissionSlice';
import { setShopIdList } from './redux/appSlice';
import { connect } from 'react-redux';
import { withNavigate } from './hookInjection/withNavigate';
import { APIData } from './constants/APIAdresses';
import { withSearchParams } from './hookInjection/withSearchParams';
import { login } from './APIHelper';


export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            passwordVisible: false,
            username: "",
            password: "",
            loginError: false,
            totp: "",
            totpActive: false,
            totpWrong: false,
        }
        this.searchParams = new URLSearchParams(window.location.search);
        this.checkInProgress = false;
        this.checkDone = false;
        this.checkSession();
        
    }
    checkSession() {
        if (!this.checkInProgress) {
            this.checkInProgress = true;
            var bodyParamsObj = {
                // "login_type": LOGINTYPE.INTERNAL,
                // "username": this.userLogin,
                // "password": this.userPassword,
            }
            let uri = APIData.USER + "/auth/session/checkCreate";
            fetch(uri, { body: JSON.stringify(bodyParamsObj), method: "POST", credentials: "include", headers: { 'Content-Type': 'application/json' } })
                .then(response => {
                    this.checkDone = true;
                    if(response.status === 200){
                        this.props.setLoggedIn();
                        console.log("Login Answer 200 yeay")
                        return response.json();
                    }else{
                        console.log("Login Answer not 200:: ",response.status);
                    }
                    // return response.json();
                })
                .then(result => {
                    if(result != null){
                        console.log("Result for loginchecK: ",result);
                        this.props.setUserRole(result.role);
                        this.props.navigate("/dashboard");
                    }
                })
                .catch(e => {
                    this.checkDone = true;
                    console.log("Error while checkSession...: ", e);
                })
        }
    }
    login(){
        if(this.state.username.length > 0 && this.state.password.length > 0){
            login(this.state.username, this.state.password).then(result => {
                console.log("Login Result: ",result);
                if(result.role != null) {
                    result.role = parseInt(result.role);
                    if(result.role >= ROLES.SHOPMANAGER){
                        this.props.setUserRole(result.role);
                        this.props.setLoggedIn();
                        this.props.setFullname(result.fullname);
                        // result.shopIdList.push(2);
                        this.props.setShopIdList(result.shopIdList);
                        this.props.navigate("/dashboard");
                    }else{
                        console.log("Role is less than shopmanager.");
                    }
                }else{
                    console.log("Role after login is null!")
                }
            }).catch(e => {
                console.log("Error while login on Login page: ",e);
            })
        }
    }
    togglePasswordVisibility() {
        this.setState({ passwordVisible: !this.state.passwordVisible });
    }
    render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <Header title="PartnerHub - Login" noMenu noBack noLogout />
                {/* <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    This is an error alert — <strong>check it out!</strong>
                </Alert> */}
                { this.searchParams?.get("logout") != null &&
                    <p style={{color:"#676767", textAlign:"center"}}>Sie wurden erfolgreich abgemeldet.</p>
                }
                <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: -100, backgroundColor: "#FAFAFA", width: "100%", height: "100%" }}>
                    {!this.state.totpActive ?
                        <>
                            {this.state.loginError &&
                                <Typography style={{ marginBottom: 15, color: "red", fontStyle: "italic" }}>Benutzerdaten falsch...</Typography>
                            }
                            <TextField value={this.state.username} onChange={(evt) => { this.setState({ username: evt.target.value, loginError: false }) }} onKeyDown={(evt) => { if (evt.key === "Enter") {  evt.target.blur(); this.passwordFieldRef.focus(); evt.preventDefault() } }} style={{ minWidth: "300px", width: "10%", backgroundColor: "#fff" }} variant='outlined' label='Benutzername'></TextField>
                            <TextField inputRef={(ref) => { this.passwordFieldRef = ref }} value={this.state.password} onChange={(evt) => { this.setState({ password: evt.target.value, loginError: false }) }} onKeyDown={(evt) => { if (evt.key === "Enter") { evt.target.blur(); evt.preventDefault() } }} type={this.state.passwordVisible ? "text" : 'password'} style={{ minWidth: "300px", width: "10%", backgroundColor: "#fff", marginTop: 15 }} variant='outlined' label='Passwort'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label='toggle password visibility' onMouseDown={() => { this.togglePasswordVisibility() }} onClick={() => { this.togglePasswordVisibility() }}>
                                                {this.state.passwordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>)
                                }} />
                            <Button onClick={() => { this.login(); }} style={{ minWidth: "300px", width: "10%", marginTop: 15 }} variant='contained'>Einloggen</Button>
                        </>
                        :
                        <>
                            {this.state.totpWrong &&
                                <Typography style={{ marginBottom: 15, color: "red", fontStyle: "italic" }}>Code falsch...</Typography>
                            }
                            <TextField  inputProps={{ maxLength: 6}} value={this.state.totp} onChange={(evt) => { this.setState({ totp: evt.target.value, totpWrong:false }) }} onKeyDown={(evt) => { if (evt.key === "Enter") { console.log("Blurring Username"); evt.target.blur(); this.passwordFieldRef.focus(); evt.preventDefault() } }} style={{ minWidth: "300px", width: "10%", backgroundColor: "#fff", letterSpacing: "20px", textAlign:"center" }} variant='outlined' label='2FA-Code'></TextField>
                            <Button onClick={() => { this.checkTOTP() }} style={{ minWidth: "300px", width: "10%", marginTop: 15 }} variant='contained'>Senden</Button>
                        </>
                    }
                </div>
            </div>
        )
    }

}
function mapDispatchToProps(dispatch) {
    return {
        setLoggedIn: () => {
            dispatch(setLoggedIn(true));
        },
        setUserRole: (role) => {
            dispatch(setRole(role));
        },
        setFullname: (fullname) => { dispatch(setFullname(fullname)); },
        setShopIdList: (shopIdList) => { dispatch(setShopIdList(shopIdList)); }
    }
}
export default connect(null, mapDispatchToProps)( withNavigate(Login));
// export default connect(null, mapDispatchToProps)(withSearchParams(Login));
