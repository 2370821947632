import React, { Component } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export class YearSelector extends Component {
    constructor(props){
        super(props);
        let currentYear = new Date().getFullYear();
        this.state = {
            yearSelection: currentYear,
            yearList : Array.from({ length: 10 }, (_, index) => currentYear - index)
        }
    }
    render() {
        return (
            <FormControl fullWidth>
                <InputLabel shrink={this.state.yearSelection != null} id="demo-simple-select-label">Jahr</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.yearSelection}
                    label="Benutzer"
                    onChange={(evt) => { this.setState({yearSelection: evt.target.value}); this.props.onChange(evt.target.value); }}
                >
                    {this.state.yearList?.map(item => {
                        return <MenuItem key={item} value={item}>{item}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }
}

export default YearSelector
