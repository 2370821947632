import React, { Component, useEffect, useState } from 'react'
import { checkSession } from '../APIHelper'
import { CircularProgress, Typography } from '@mui/material';
import { ROLES, setFullname, setLoggedIn, setRole } from '../redux/permissionSlice';
import { setShopIdList } from '../redux/appSlice';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

const loadingElement = (
    <div style={{width:"100vw", flexDirection:"column", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <img width={64} height={64} src={require("../assets/icons/viveel_logo128x128.png")}/>
        <CircularProgress size={32} style={{marginTop:5}}/>
        <Typography style={{color:"#979797", marginTop:5}} fontSize="small">Loading...</Typography>
    </div>
)
/**
 * WARNING: All components using this as a wrapper must provide the props.role attribute and the props.setRole() function.
 * 
 * @param {*} Component 
 * @returns 
 */
export const PermissionAreaComponent = (Component) => {
    const Wrapper = (props) => {
        const [isLoading, setIsLoading] = useState(true);
        const navigate = useNavigate();
        useEffect(() => {
            if(props.role >= ROLES.SHOPMANAGER){
                console.log("Role was already set, redirecting...");
                setIsLoading(false);
            }else{
                console.log("Role is not set checking session...");

                checkSession().then(result => {
                        console.log("Session exists auto-login...");
                        if(props.setRole != null){
                            props.setRole(result.role);
                        }
                        props.setLoggedIn();
                        props.setFullname(result.fullname);
                        result.shopIdList.push(3);
                        props.setShopIdList(result.shopIdList);
                        setIsLoading(false);
                    }).catch(e => {
                        console.log("Error in PermissionAreaComponent: ",e);
                        console.log("User not logged in or session expired, redirecting to login");
                        // window.history("/login");
                        navigate("/")
                    })
            }
            
        }, []) // an empty watch parameter for useEffect will only execute one time. This will avoid multiple calls due to render().
        return isLoading ? loadingElement : <Component {...props} />
    }
    function mapDispatchToProps(dispatch){
        return {
            setLoggedIn: () => { dispatch(setLoggedIn(true))},
            setFullname: (fullname) => { dispatch(setFullname(fullname))},
            setShopIdList: (list) => { dispatch(setShopIdList(list))},
            setRole: (role) => { dispatch(setRole(role)) } 
        }
    }
    return connect(null, mapDispatchToProps)(Wrapper);
}
