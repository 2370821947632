import React, { Component } from 'react'
import Header from '../Components/Header'
import { PermissionAreaComponent } from '../hookInjection/PermissionAreaComponent';
import { connect } from 'react-redux';
import { Button, TextField } from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import ShopSelector from '../Components/ShopSelector';
import { POS_TYPES, addPosConfig, deletePosConfig, loadPosConfig, updatePosConfig } from '../APIHelper';
import { setPosConfig } from '../redux/appSlice';

export class LiefersoftConfigPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: "",
            pw: "",
            cid: ""
        }
        this.initialPosconfig = {};
    }
    save() {
        if (this.state.pw !== this.initialPosconfig.password || this.state.login !== this.initialPosconfig.login || this.state.cid !== this.initialPosconfig.companyId) {
            console.log("Changes have been made -> Change");
            let newPosConfigBody = {"login":this.state.login, "password": this.state.pw ,"companyId": this.state.cid}
            if(this.props.posConfig.id != null){
                updatePosConfig(this.props.currentShopId, this.props.posConfig.id, POS_TYPES.LIEFERSOFT, newPosConfigBody)
                .then(() => {
                    this.initialPosconfig = {...newPosConfigBody}
                }).catch(e => {
                    console.log("Error while update: ",e)
                    alert("Beim Aktualisieren der Konfiguration ist ein Fehler aufgetreten.");
                })
            }else{
                addPosConfig(this.props.currentShopId, POS_TYPES.LIEFERSOFT, newPosConfigBody)
                .then(() => {
                    loadPosConfig(this.props.currentShopId, POS_TYPES.LIEFERSOFT)
                    .then(result => {
                        this.props.setPosConfig(result);
                        if(result.configFields != null){
                            this.initialPosconfig = {...result.configFields};
                            this.setState({login: result?.configFields?.login, pw: result?.configFields?.password, cid: result?.configFields?.companyId});
                        }
                    }).catch(e => {
                        alert("Beim Hinzufügen der Konfiguration ist ein Fehler aufgetreten (001).");
                        console.log("Error while adding posConfig: ",e);
                    })
                }).catch(e => {
                    alert("Beim Hinzufügen der Konfiguration ist ein Fehler aufgetreten (002).");
                })
            }
        } else {
            alert("Everything already up to date.");
        }
    }
    delete() {
        let posConfBackup = {...this.props.posConfig};
        let posInitialConfigBackup = {...this.initialPosconfig}
        let login = this.state.login;
        let pw = this.state.pw;
        let cid = this.state.cid;
        this.props.setPosConfig({});
        this.initialPosconfig= {};

        this.setState({login:"", pw:"", cid:""})
        deletePosConfig(this.props.currentShopId, this.props.posConfig.id)
        .catch(e => {
            alert("Fehler beim Löschen der Anbindung.");
            console.log("WinOrder - Fehler beim Löschen der Anbindung: ",e);
            this.props.setPosConfig({...posConfBackup});
            this.initialPosconfig={...posInitialConfigBackup};
            this.setState({login:login, pw: pw, cid: cid})
        })
    }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className='mainView'>
                <Header title="Liefersoft - Konfiguration" />

                <div style={{ flex: 1, display: "flex", flexDirection: "column", padding: 20, overflowY: "scroll" }}>
                    <div style={{ flex: 1 }}>
                        <ShopSelector posId={POS_TYPES.LIEFERSOFT} returnInitialPosConfig={(posConfig) => { this.initialPosconfig = posConfig; this.setState({ login: posConfig.login != null ? posConfig.login : "", pw: posConfig.password != null ? posConfig.password : "", cid: posConfig.companyId != null ? posConfig.companyId : "" }) }} />
                        <p>Erstelle zuerst die Zugangsdaten bei Liefersoft und trage die Felder entsprechend ein:</p><br /><br />
                        <TextField style={{ width: "100%", marginTop: 10 }} label='Login' value={this.state.login} onChange={(evt) => { this.setState({ login: evt.target.value }) }} />
                        <TextField style={{ width: "100%", marginTop: 10 }} label='Password' value={this.state.pw} onChange={(evt) => { this.setState({ pw: evt.target.value }) }} />
                        <TextField style={{ width: "100%", marginTop: 10 }} label='CompanyID' value={this.state.cid} onChange={(evt) => { this.setState({ cid: evt.target.value }) }} />
                    </div>
                    <div>
                        <Button onClick={() => { this.save() }} style={{ marginTop: 10, width: "100%" }} variant='contained' startIcon={<Save />}>Speichern</Button>
                        <Button onClick={() => { this.delete() }} style={{ marginTop: 10, width: "100%" }} variant='contained' color='error' startIcon={<Delete />}>Löschen</Button>
                    </div>
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {

    return {
        shopIdList: state.app.shopIdList,
        currentShopId: state.app.currentShopId,
        posConfig: state.app.posConfig
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setPosConfig: (posConf) => {
            dispatch(setPosConfig(posConf));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionAreaComponent(LiefersoftConfigPage))
