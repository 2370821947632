import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import { Provider } from 'react-redux';
import store from './redux/store';
import POSIntegration from './POSIntegration';

import LiefersoftConfigPage from './POSIntegrationPages/LiefersoftConfigPage';
import WinOrderConfigPage from './POSIntegrationPages/WinOrderConfigPage';
import ExpertOrderConfigPage from './POSIntegrationPages/ExpertOrderConfigPage';
import Ready2OrderConfigPage from './POSIntegrationPages/Ready2OrderConfigPage';
import InvoicePage from './InvoicePage';
const router = createBrowserRouter([
    { path: "/", element: <Login />, errorElement: <p>Error 404 not found...</p> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/invoice", element: <InvoicePage /> },
    { path: "/posconfig", element: <POSIntegration /> },
    { path: "/posconfig/ready2order", element: <Ready2OrderConfigPage /> },
    { path: "/posconfig/liefersoft", element: <LiefersoftConfigPage /> },
    { path: "/posconfig/winorder", element: <WinOrderConfigPage /> }, 
    { path: "/posconfig/expertorder", element: <ExpertOrderConfigPage />}
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider  router={router}  />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
