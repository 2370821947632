import { useSearchParams } from "react-router-dom";


export const withSearchParams = (Component) => {
    const Wrapper = (props) => {
        const searchParams = useSearchParams();
        return (
            <Component
                searchParams={searchParams}
                {...props}
            />
        );
    };

    return Wrapper;
};