import React, { Component } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export class R2OPaymentMethodSelector extends Component {
    render() {
        return (
            <FormControl style={{marginTop:15}} fullWidth>
                <InputLabel shrink={this.props.currentId != null} id="demo-simple-select-label">{this.props.label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.props.currentId}
                    label={this.props.label}
                    onChange={(evt) => { this.props.changeSelection(evt.target.value) }}
                >
                    {this.props.pmList?.map(item => {
                        return <MenuItem key={item.payment_id} value={item.payment_id}>{item.payment_id} - {item.payment_name}</MenuItem>
                    })}
                </Select>
            </FormControl>
        )
    }
}

export default R2OPaymentMethodSelector
