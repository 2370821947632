import { createSlice } from "@reduxjs/toolkit";


export const appSlice = createSlice({
    name:'app',
    initialState: {
        shopIdList: [],
        shopData: [],
        currentShopId: '',
        posConfig: {},
    },
    reducers: {
        setShopIdList: (state, arg) => { state.shopIdList=arg.payload},
        setShopData: (state, arg) => { state.shopData=arg.payload},
        setCurrentShopId: (state, arg) => {state.currentShopId=arg.payload} ,
        setPosConfig: (state, arg) => { state.posConfig=arg.payload}
    }
})

export const { setShopIdList, setShopData,setCurrentShopId, setPosConfig } = appSlice.actions

export default appSlice.reducer