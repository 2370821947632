import React, { Component } from 'react'
import { PermissionAreaComponent } from './hookInjection/PermissionAreaComponent'
import { connect } from 'react-redux'
import { setRole } from './redux/permissionSlice'
import "./assets/css/general.css";
import Header from './Components/Header';
import DashboardButton from './Components/DashboardButton';
import YearSelector from './Components/YearSelector';
import ShopSelector from './Components/ShopSelector';
import { Link, Typography } from '@mui/material';
import { getInvoiceFileList } from './APIHelper';

export class POSIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
        }
        this.currentYear = new Date().getFullYear();
        // if(this.props.currentShopId !== ""){
        //     this.loadAndSetFileList(this.props.currentShopId, this.currentYear)
        // }
    }
    loadAndSetFileList(shopId, year) {
        console.log("Loading and setting file list..")
        getInvoiceFileList(shopId, year)
            .then(result => {
                console.log("We have an result here:", result);
                this.setState({ fileList: result })
            }).catch(e => {
                console.log("Error while loadAndSetFileList: ", e);
            })
    }
    handleShopChange(id) {
        console.log("HandleShopChange: ", id);
        // if(this.currentYear != null){
        this.loadAndSetFileList(id, this.currentYear != null ? this.currentYear : new Date().getFullYear());
        // }
    }
    handleYearChange(year) {
        this.currentYear = year;
        console.log("HandleYEarChange: ", year, " CURRENTSHOPID: ", this.props.currentShopId);
        if (this.props.currentShopId !== "") {
            this.loadAndSetFileList(this.props.currentShopId, this.currentYear)
        }
    }
    componentWillUnmount() {
        this.currentYear = null;
    }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className='mainView'>
                <Header title="Rechnungen" />
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", margin: "2.5%", }}>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <div style={{ flex: 1, }}>
                            <div style={{ width: "95%" }}>
                                <ShopSelector onChange={(id) => { this.handleShopChange(id) }} />
                            </div>
                        </div>
                        <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
                            <div style={{ width: "95%" }}>
                                <YearSelector onChange={(year) => { this.handleYearChange(year) }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ width: "100%", marginTop: 25, display: "flex", justifyContent: "center" }}>
                        {this.state.fileList.map((fileName,index) => {
                            return (<Link key={this.currentYear+"_"+index} style={{ width: "100%", display:"flex", justifyContent:"center" }} target="_blank" rel="noopener noreferrer" href={"https://viveel.de:3003/invoice/" + this.props.currentShopId + "/year/" + this.currentYear + "/" + fileName}>
                                <div style={{ width: "100%", padding: 25, backgroundColor: "#DADADA", borderRadius: 10, borderColor: "#AAAAAA", borderWidth: 0.5 }}>
                                    <Typography style={{ textDecoration: "none" }}>{fileName}</Typography>
                                </div>
                            </Link>)
                        })}
                        {this.state.fileList.length == 0 &&
                        <div style={{ width: "100%", padding: 25, backgroundColor: "#DADADA", borderRadius: 10, borderColor: "#AAAAAA", borderWidth: 0.5 }}>
                            <Typography>Keine Dokumente</Typography>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        role: state.permission.role,
        currentShopId: state.app.currentShopId,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setRole: (role) => {
            dispatch(setRole(role));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionAreaComponent(POSIntegration))
// export default PermissionAreaComponent(POSIntegration)
