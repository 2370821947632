import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { loadPosConfig, loadShopData } from '../APIHelper';
import { setCurrentShopId, setPosConfig } from '../redux/appSlice';
import { setShopData } from '../redux/appSlice';

export class ShopSelector extends Component {
    constructor(props) {
        super(props);
        this.searchParams = new URLSearchParams(window.location.search);
        // this.accToken = this.searchParams.get("accountToken");
        this.shopIdPreSelection = this.searchParams.get("shopId");
        this.state = {
            shopSelection:  this.props.shopList?.length > 0 ? this.props.shopList[0].id : ""
        }
        console.log("#################SHOPSELECTOR CONSTRUCTOR...");
        if (this.props.shopList?.length == 0) {
            // load shop data
            loadShopData(this.props.shopIdList).then(result => {
                this.props.setShopDataList(result);
                if (result.length > 0) {
                    this.props.setCurrentShopId(this.shopIdPreSelection != null ? this.shopIdPreSelection : result[0].id);
                    
                    if (this.props.posId != null) {
                        this.__loadPosConfig(result[0].id, this.props.posId)
                    }
                    if(this.props.onChange != null){
                        this.props.onChange(this.shopIdPreSelection != null ? this.shopIdPreSelection : result[0].id)
                    }
                    // this.setState({shopSelection: result[0].id});
                }
            }).catch(() => {
                console.log("Error while loading shops in shopSelector.");
            })
        } else {
            if (this.props.posId != null) {
                this.__loadPosConfig(this.props.shopList[0].id, this.props.posId);
            }
        }
    }
    componentWillUnmount(){
        this.props.setPosConfig({});
    }
    __loadPosConfig(shopId, posId) {
        loadPosConfig(shopId, posId)
            .then(result => {
                console.log("PosConfig: ", result);
                // if(Object.keys(result).length  > 0)
                this.props.setPosConfig(result);
                if(this.props.returnInitialPosConfig != null){
                    this.props.returnInitialPosConfig(result.configFields);
                }
            }).catch(e => {
                console.log("Error while loading posConfig in ShopSelector: ", e);
            })
    }
    changeShop(evt) {
        this.props.setCurrentShopId(evt.target.value);
        if(this.props.posId != null){
            this.__loadPosConfig(evt.target.value, this.props.posId);
        }
        if(this.props.onChange != null){
            this.props.onChange(evt.target.value)
        }
    }
    render() {
        return (
            <FormControl disabled={this.props.disabled} fullWidth>
                <InputLabel shrink={true} id="demo-simple-select-label">Shop</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.props.currentShopId}
                    label="Shop"
                    onChange={(evt) => { this.changeShop(evt) }}
                >
                    {this.props.shopList?.map(item => {
                        return <MenuItem key={item.id} value={item.id}>{item.id} - {item.name}</MenuItem>
                    })}
                    {/* <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
            </FormControl>
        )
    }
}
function mapStateToProps(state) {
    return {
        shopIdList: state.app.shopIdList,
        shopList: state.app.shopData,
        currentShopId: state.app.currentShopId
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setShopDataList: (list) => { dispatch(setShopData(list)) },
        setCurrentShopId: (id) => { dispatch(setCurrentShopId(id)) },
        setPosConfig: (posConfig) => { dispatch(setPosConfig(posConfig))}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ShopSelector)
