import React, { Component } from 'react'
import Header from '../Components/Header'
import { PermissionAreaComponent } from '../hookInjection/PermissionAreaComponent';
import { connect } from 'react-redux';
import { Button, TextField, Typography } from '@mui/material';
import { Delete, Save } from '@mui/icons-material';
import ShopSelector from '../Components/ShopSelector';
import { POS_TYPES, addPosConfig, deletePosConfig, loadPosConfig, updatePosConfig } from '../APIHelper';
import { setPosConfig } from '../redux/appSlice';
const shopList = [
    { id: 1, name: "Viveel Pizza" },
    { id: 2, name: "Testshop" },
]
export class WinOrderConfigPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ""
        }
        this.initialPosconfig = {}
    }
    save() {
        if (this.initialPosconfig.email == null && this.state.email.length > 0 || this.initialPosconfig.email != null && this.initialPosconfig.email !== this.state.email) {
            console.log("Changes have been made -> Change");
            let newPosConfigBody = {"email":this.state.email}
            if(this.props.posConfig.id != null){
                
                updatePosConfig(this.props.currentShopId, this.props.posConfig.id, POS_TYPES.WINORDER, newPosConfigBody)
                .then(() => {
                    this.initialPosconfig = {...newPosConfigBody}
                }).catch(e => {
                    console.log("Error while update: ",e)
                    alert("Beim Aktualisieren der Konfiguration ist ein Fehler aufgetreten.");
                })
            }else{
                addPosConfig(this.props.currentShopId, POS_TYPES.WINORDER, newPosConfigBody)
                .then(() => {
                    loadPosConfig(this.props.currentShopId, POS_TYPES.WINORDER)
                    .then(result => {
                        this.props.setPosConfig(result);
                        if(result.configFields != null){
                            this.initialPosconfig = {...result.configFields};
                            this.setState({email: result?.configFields?.email});
                        }
                    }).catch(e => {
                        alert("Beim Hinzufügen der Konfiguration ist ein Fehler aufgetreten (001).");
                        console.log("Error while adding posConfig: ",e);
                    })
                }).catch(e => {
                    alert("Beim Hinzufügen der Konfiguration ist ein Fehler aufgetreten (002).");
                })
            }
        } else {
            alert("Everything already up to date.");
        }
    }
    delete() {
        let posConfBackup = {...this.props.posConfig};
        let posInitialConfigBackup = {...this.initialPosconfig}
        let mail = this.state.email;
        this.props.setPosConfig({});
        this.initialPosconfig= {};

        this.setState({email:""})
        deletePosConfig(this.props.currentShopId, this.props.posConfig.id)
        .catch(e => {
            alert("Fehler beim Löschen der Anbindung.");
            console.log("WinOrder - Fehler beim Löschen der Anbindung: ",e);
            this.props.setPosConfig({...posConfBackup});
            this.initialPosconfig={...posInitialConfigBackup};
            this.setState({email:mail})
        })
    }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className='mainView'>
                <Header title="WinOrder - Konfiguration" />
                <div style={{ padding: 20, overflowY: "scroll" }}>
                    <div style={{ flex: 1, display: "flex", flexDirection: "column", padding: 20, overflowY: "scroll" }}>
                        <div style={{ flex: 1 }}>
                            <ShopSelector posId={POS_TYPES.WINORDER} returnInitialPosConfig={(posConfig) => { this.initialPosconfig = posConfig; this.setState({ email: posConfig.email != null ? posConfig.email : "" }) }} />
                            <h2>Anbindung über Benutzername & Passwort (empfohlen)</h2>
                            <p>Es wird empfohlen die Anbindung in der WinOrder Kasse selbst durchzuführen</p>
                            <p>Verwenden hierzu den Verbindungsassistenten im Online-Shop Anbindung der WinOrder Kasse und trage als Endpunkt folgenden Link ein.<br />
                                <span style={{ color: '#0078a1', fontWeight: "bold" }}>https://viveel.de:3001/winorder/<span style={{ color: '#f19535' }}>DEINSHOPID</span>/GetNewOrders</span><br />
                                Ersetze dabei DEINSHOPID durch die ID deines Shops, dass angebunden werden soll.<br /><br />
                                Du hast folgende ShopID's zur auswahl:<br /><br />
                                <span style={{ color: '#f19535', fontWeight: "bold" }}>{this.props.shopIdList.join(",")}</span><br /><br />
                                Beispiel:<br /><br />
                                <span style={{ color: '#0078a1', fontWeight: "bold" }}>https://viveel.de:3001/winorder/<span style={{ color: '#f19535' }}>{this.props.currentShopId != null ? this.props.currentShopId : 0}</span>/GetNewOrders</span><br />
                            </p>
                            <p>Als Benutzername und Passwort, kannst du deine Zugangsdaten für den ShopManager bzw. den Partner Hub verwenden.</p>
                            <br /><br />
                            <h2>Anbindung über E-Mail Adresse:</h2>
                            <Typography>Zuerst musst du in WinOrder den Online-Shop anlegen und die E-Mail adresse dort Konfigurieren.</Typography>
                            <br /><Typography>Anschließend kannst du im folgenden die E-Mail adresse eintragen, um die Übermittlung per E-Mail zu konfigurieren.<br />Anders als bei der Methode mit Benutzername & Passwort bekommt Viveel durch E-Mail Konfiguration keine Rückmeldung von der Kasse über Lieferzeiten oder Status der Bestellung. Daher empfehlen wir die Konfiguration mit Benutzername & Passwort.</Typography>
                            <br />
                            <TextField onChange={(evt) => { this.setState({ email: evt.target.value }) }} style={{ width: "100%" }} label='E-Mail Adresse' value={this.state.email} />
                        </div>
                    </div>
                    <div>

                        <Button onClick={() => { this.save() }} style={{ marginTop: 10, width: "100%" }} variant='contained' startIcon={<Save />}>Speichern</Button>
                        <Button onClick={() => { this.delete() }} disabled={this.props.posConfig.id != null ? false : true} style={{ marginTop: 10, width: "100%" }} variant='contained' color='error' startIcon={<Delete />}>Löschen</Button>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        shopIdList: state.app.shopIdList,
        shopIdData: state.app.shopData,
        currentShopId: state.app.currentShopId,
        posConfig: state.app.posConfig
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setPosConfig: (posConf) => {
            
            dispatch(setPosConfig(posConf));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionAreaComponent(WinOrderConfigPage))
