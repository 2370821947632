import React, { Component } from 'react'
import { useSearchParams } from 'react-router-dom'
import Header from '../Components/Header';
import { connect } from 'react-redux';
import { PermissionAreaComponent } from '../hookInjection/PermissionAreaComponent';
import ShopSelector from '../Components/ShopSelector';
import { POS_TYPES, addUpdateR2OConfig, deletePosConfig, getR2OGrantAccessUri, getR2OPaymentMethod, getR2OUsers } from '../APIHelper';
import { Button, Typography } from '@mui/material';
import { Delete, Save, Link, Cancel, Inventory } from '@mui/icons-material';
import R2OUserSelector from '../Components/R2OUserSelector';
import R2OPaymentMethodSelector from '../Components/R2OPaymentMethodSelector';
import { setCurrentShopId, } from '../redux/appSlice';
// shopId = 1
//     & otc=9tK4sETb3Kef1hvEAFMPVWln4BF8NAWOLfLQoC2bFECi20eeW1dkIc1Ndu9oxLY7
//         & accountToken=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5yZWFkeTJvcmRlci5hdCIsImp0aSI6InBTbUl2T2VMWW5mbjVCUUxwMllpdzI3aE0xaVdOeGxkZXlrVHMvYk5HTzQ9IiwiaWF0IjoxNzAxNTY2NzkzLCJkYXRhIjp7ImNvbXBhbnlfaWQiOjE1MDY3MCwiaW50ZWdyYXRvcl9pZCI6MTk4N319.HhiUrvH4gltefDIITlWXMZIrC5WjXOTmbNbqXROMhkY & grantAccessToken=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwaS5yZWFkeTJvcmRlci5hdCIsImp0aSI6ImdmZWVRR1kvL0FtbVV2UVoxei94K2dSbDByeGkvTzQxTVdiNTJQRVdNM3c9IiwiaWF0IjoxNzAxNTY2NzQzLCJkYXRhIjp7ImFwaUludGVncmF0b3JJZCI6MTk4NywibmFtZSI6IlZpdmVlbCBHbWJIIiwiZW1haWwiOiJpbmZvQHZpdmVlbC5kZSIsImNhbGxiYWNrIjoiaHR0cHM6Ly92aXZlZWwuZGU6MzAwMS9yZWFkeTJvcmRlci9yZWRpcmVjdD9zaG9wSWQ9MSZvdGM9OXRLNHNFVGIzS2VmMWh2RUFGTVBWV2xuNEJGOE5BV09MZkxRb0MyYkZFQ2kyMGVlVzFka0ljMU5kdTlveExZNyJ9LCJleHAiOjE3MDE1NjczNDMsIm5iZiI6MTcwMTU2Njc0M30.zN_h4xcsROYkqKNP83BZiSmkZ5OrGlrE4re7qd11IlQ
//             & status=approved
export class Ready2OrderConfigPage extends Component {
    constructor(props) {
        super(props);
        this.searchParams = new URLSearchParams(window.location.search);
        this.accToken = this.searchParams.get("accountToken");
        this.shopId = this.searchParams.get("shopId");
        this.otc = this.searchParams.get("otc")
        this.status = this.searchParams.get("status")
        this.state = {
            currentUserId: '',
            userList: [],
            cashPMMethodId: '',
            cardPMMethodId: '',
            onlinePMMethodId: '',
            pmList: [],
            coupled: false,
        }
        if (this.accToken != null) {
            this.loadR2OData(this.accToken);
        }
        this.initialPosConfig = {}
    }

    save(isUpdate) {
        // /ready2order/shop/:shopId/addUpdateConfig
        let myAccToken = this.accToken != null ? this.accToken : this.initialPosConfig.accountToken;
        if (!isUpdate) {
            if (myAccToken != null && this.state.currentUserId !== "" && this.state.cashPMMethodId !== "" && this.state.cardPMMethodId !== "" && this.state.onlinePMMethodId !== "") {
                let config = {
                    accountToken: myAccToken,
                    userId: this.state.currentUserId,
                    cashPMId: this.state.cashPMMethodId,
                    cardPMId: this.state.cardPMMethodId,
                    onlinePMId: this.state.onlinePMMethodId
                }
                addUpdateR2OConfig(this.props.currentShopId, config, false).then(() => {
                    window.location.href = window.location.pathname; // this calls the current path without query params.
                }).catch(e => {
                    alert("Es ist etwas schiefgelaufen. Bitte probieren Sie es erneut oder kontaktieren den Support.");
                })
            }
        } else {
            let config = {
                posConfigId: this.props.posConfig.id,
                accountToken: myAccToken,
                userId: this.state.currentUserId,
                cashPMId: this.state.cashPMMethodId,
                cardPMId: this.state.cardPMMethodId,
                onlinePMId: this.state.onlinePMMethodId
            }
            addUpdateR2OConfig(this.props.currentShopId, config, true).then(() => {
                window.location.href = window.location.pathname; // this calls the current path without query params.
            }).catch(e => {
                alert("Es ist etwas schiefgelaufen. Bitte probieren Sie es erneut oder kontaktieren den Support.");
            })
        }
        // addUpdateR2OConfig()
    }
    couple() {
        getR2OGrantAccessUri(this.props.currentShopId);
    }
    syncProducts() {

    }
    delete() {
        let stateBackup = { ...this.state };
        let initPosConfBackup = { ...this.initialPosConfig };
        let defaultPid = this.initialPosConfig.defaultPID;
        let accToken = initPosConfBackup.accountToken;
        this.setState({
            currentUserId: '',
            userList: [],
            cashPMMethodId: '',
            cardPMMethodId: '',
            onlinePMMethodId: '',
            pmList: [],
            coupled: false,
        })
        deletePosConfig(this.props.currentShopId, this.props.posConfig.id, defaultPid, accToken).catch(() => {
            this.setState({ ...stateBackup });
            this.initialPosConfig = initPosConfBackup;
        })
    }
    cancel() {
        // window.location.reload();
        window.location.href = window.location.pathname;
    }
    loadR2OData(accToken) {
        getR2OUsers(accToken)
            .then(result => {
                console.log("R2OUsersResult: ", result);
                let uid = this.initialPosConfig?.userId != null ? this.initialPosConfig?.userId : result.length > 0 ? result[0].user_id != null ? result[0].user_id : '' : ''
                this.setState({ userList: result, currentUserId: uid })
            }).catch(e => { console.log("Error while getR20Users in configpage: ", e) });
        getR2OPaymentMethod(accToken)
            .then(result => {
                console.log("R2OPMResults: ", result);
                // this.initialPosConfig= {cardPMId: '679074'};
                let defaultId = result.length > 0 ? result[0].payment_id != null ? result[0].payment_id : '' : ''
                let cpmId = this.initialPosConfig?.cashPMId != null ? this.initialPosConfig?.cashPMId : defaultId;
                let cardPmId = this.initialPosConfig?.cardPMId != null ? this.initialPosConfig?.cardPMId : defaultId;
                let onlinePmId = this.initialPosConfig?.onlinePMId != null ? this.initialPosConfig?.onlinePMId : defaultId;
                this.setState({ pmList: result, cashPMMethodId: cpmId, cardPMMethodId: cardPmId, onlinePMMethodId: onlinePmId });
            }).catch(e => { console.log("Error while getR2OPM in configpage: ", e) });
    }
    handleInitialPosConfig(posConf) {
        this.setState({
            currentUserId: '',
            userList: [],
            cashPMMethodId: '',
            cardPMMethodId: '',
            onlinePMMethodId: '',
            pmList: [],
            coupled: false,
        })
        this.initialPosConfig = posConf;
        if (posConf?.accountToken != null) {
            this.setState({ coupled: true })
            this.loadR2OData(posConf?.accountToken)
        }
    }

    // shopSelectionChanged(){
    //     this.setState({
    //         currentUserId: '',
    //         userList: [],
    //         cashPMMethodId: '',
    //         cardPMMethodId: '',
    //         onlinePMMethodId: '',
    //         pmList: [],
    //         coupled: false,
    //     })
    // }
    render() {
        return (
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className='mainView'>
                <Header title="Ready2Order - Konfiguration" />

                <div style={{ flex: 1, display: "flex", flexDirection: "column", padding: 20, overflowY: "scroll" }}>
                    <div style={{ flex: 1 }}>
                        <ShopSelector onChange={() => { this.shopSelectionChanged() }} disabled={this.shopId != null} posId={POS_TYPES.READY2ORDER} returnInitialPosConfig={(posConfig) => { this.handleInitialPosConfig(posConfig) }} />
                        <Typography style={{ marginTop: 25, marginBottom: 15, color: '#424242' }}>Die Benutzerkonfiguration bestimmt, durch welchen Benutzer die Anfragen in der Kasse getätigt werden. Du solltest darauf achten, dass der ausgewählte Benutzer über die erforderlichen Rechte verfügt.</Typography>
                        <R2OUserSelector currentId={this.state.currentUserId} userList={this.state.userList} changeSelection={(id) => { this.setState({ currentUserId: id }) }} />
                        <Typography style={{ marginTop: 25, marginBottom: 15, color: '#424242' }}>Die Konfiguration der Zahlungsmethoden gewährleistet die korrekte Zuordnung der Zahlungsmethoden in deinem Kassensystem. Dadurch werden deine Umsätze entsprechend dem richtigen Zahlungstyp verarbeitet, was einen reibungslosen Ablauf deiner Buchhaltung sicherstellt.</Typography>
                        <R2OPaymentMethodSelector label="Barzahlung" pmList={this.state.pmList} currentId={this.state.cashPMMethodId} changeSelection={(id) => { this.setState({ cashPMMethodId: id }) }} />
                        <R2OPaymentMethodSelector label="Kartenzahlung" pmList={this.state.pmList} currentId={this.state.cardPMMethodId} changeSelection={(id) => { this.setState({ cardPMMethodId: id }) }} />
                        <R2OPaymentMethodSelector label="Onlinezahlung" pmList={this.state.pmList} currentId={this.state.onlinePMMethodId} changeSelection={(id) => { this.setState({ onlinePMMethodId: id }) }} />
                        {/* <p>Ready2OrderConfigPage - SHOPIDLIST: {this.props.shopIdList}</p>
                        <p>searchParamsShopId: {this.searchParams.get("shopId")}</p>
                        <p>searchParamsOTC: {this.searchParams.get("otc")}</p>
                        <p>searchParamsAccToken: {this.searchParams.get("accountToken")}</p>
                        <p>searchParamsStatus: {this.searchParams.get("status")}</p> */}
                    </div>
                    {this.accToken == null ?
                        <div>
                            <Button disabled={!this.state.coupled} onClick={() => { this.save(true) }} style={{ marginTop: 10, width: "100%" }} variant='contained' startIcon={<Save />}>Speichern</Button>
                            <Button disabled={!this.state.coupled} onClick={() => { this.delete() }} style={{ marginTop: 10, width: "100%" }} variant='contained' color='error' startIcon={<Delete />}>Löschen</Button>
                            {this.state.coupled &&
                                <Button onClick={() => { this.syncProducts() }} style={{ marginTop: 10, width: "100%" }} variant='outlined' startIcon={<Inventory />}>Produkte Synchronisieren</Button>
                            }
                            {this.state.currentUserId === '' &&
                                <Button onClick={() => { this.couple() }} style={{ marginTop: 10, width: "100%" }} variant='outlined' color='primary' startIcon={<Link />}>Koppeln</Button>
                            }
                        </div>
                        :
                        <div>
                            <Button onClick={() => { this.save() }} style={{ marginTop: 10, width: "100%" }} variant='contained' startIcon={<Save />}>Speichern 1</Button>
                            <Button onClick={() => { this.cancel() }} style={{ marginTop: 10, width: "100%" }} variant='contained' color='error' startIcon={<Cancel />}>Abbrechen</Button>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        shopIdList: state.app.shopIdList,
        currentShopId: state.app.currentShopId,
        posConfig: state.app.posConfig
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setCurrentShopId: (id) => { dispatch(setCurrentShopId(id)) },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PermissionAreaComponent(Ready2OrderConfigPage))
